import Canva from "components/Canva";
import React, { useEffect, useRef, useState } from "react";

const fonts = [
  "Caveat",
  "Cookie",
  "Courgette",
  "DancingScript",
  "GloriaHallelujah",
  "Parisienne",
];

const isIos = () => {
  const ua = navigator.userAgent;
  return (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};

async function resizeImage(img, w, h, watermark = false) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = w;
  canvas.height = h;

  context.drawImage(img, 0, 0, w, h);

  if (watermark) {
    const watermark_dimesions = 200;
    const watermark = new Image();
    watermark.setAttribute("crossorigin", "anonymous");
    watermark.width = watermark_dimesions;
    watermark.height = watermark_dimesions;
    watermark.src = `${process.env.REACT_APP_PUBLIC_URL}/logo_watermark.png`;
    await new Promise((resolve) => {
      watermark.onload = () => {
        context.globalAlpha = 0.4;
        context.drawImage(
          watermark,
          (w - watermark_dimesions) / 2,
          (h - watermark_dimesions) / 2,
          200,
          200
        );
        context.globalAlpha = 1;
        resolve();
      };
    });
  }

  return canvas.toDataURL("image/png");
}

function Builder({
  options = {},
  assetsName = "",
  projectName = "",
  disabledOptions = [],
  callbackFn = () => null,
  closeHandler = () => null,
}) {
  const [builderState, setBuilderState] = useState({});
  const [accordions, setAccordions] = useState([0]);
  const canvasRef = useRef(null);

  const getEntangledOptions = (options = {}, option) => {
    const result = Object.keys(options, option).filter((opt) =>
      `${opt}`.startsWith(option.replace(/_\d+/, ""))
    );

    return result.length > 1 ? result : [];
  };

  useEffect(() => {
    const dft = {};
    Object.keys(options).forEach((key) => {
      if (key === "extras") {
        dft[key] = { ...options[key] };
      } else {
        dft[key] = options[key].options ? { ...options[key].options } : {};
      }

      Object.keys(dft[key]).forEach((key2) => {
        const entangledOptions = getEntangledOptions(options[key].options, key2)
          .sort((a, b) => a.length + b.length)
          .reverse();
        const originalOption = entangledOptions.pop();
        if (key !== "extras") {
          dft[key][key2] = dft[key][key2].options
            ? dft[key][key2].options[0].value
            : dft[key][key2].text
            ? dft[key][key2].text.value
            : undefined;
        }
        if (entangledOptions.length && key2 !== originalOption) {
          dft[key][key2] = -1;
        }

        if (disabledOptions.includes(key) && options[key].disableable) {
          dft[key].disabled = true;
        }
      });
    });
    setBuilderState(dft);
  }, [options, setBuilderState]);

  const selectOption =
    ([key, option, value]) =>
    (e) => {
      e.preventDefault();

      const deps = {};
      if (options[key].options[option].deps) {
        options[key].options[option].deps.forEach((dep) => {
          deps[dep] = value;
        });
      }

      const entangledOptions = getEntangledOptions(
        options[key].options,
        option
      );

      const clearEntangledOptions = entangledOptions.length
        ? (() => {
            const result = {};
            entangledOptions.forEach((opt) => (result[opt] = -1));
            return result;
          })()
        : null;

      setBuilderState({
        ...builderState,
        [key]: {
          ...(builderState && builderState[key] ? builderState[key] : null),
          ...clearEntangledOptions,
          [option]: value,
          ...deps,
        },
      });
    };

  const isSelected = (key, option, value) => {
    try {
      return builderState[key][option] === value;
    } catch (e) {
      return false;
    }
  };

  const setExtras =
    (key, transformer = (i) => i) =>
    (e) => {
      e.preventDefault();
      setBuilderState({
        ...builderState,
        extras: {
          ...builderState.extras,
          [key]: transformer(e.target.value),
        },
      });
    };

  const getImage = ({ width, height, watermark = false }) =>
    new Promise((resolve) => {
      const img = new Image();
      img.setAttribute("crossorigin", "anonymous");
      img.src = canvasRef.current.toDataURL("image/png");
      img.onload = () => {
        resolve(resizeImage(img, width, height, watermark));
      };
    });

  const finishCreator =
    (variant = "brak") =>
    async (e) => {
      e.preventDefault();

      const resizedImage = await getImage({
        width: builderState.extras.width * 0.35,
        height: builderState.extras.height * 0.35,
        watermark: true,
      });

      callbackFn({
        state: builderState,
        image: resizedImage,
      });
      closeHandler()(e);
      setTimeout(() => {
        if (variant) {
          document.querySelector(`#mug_filling_${variant}`).click();
        }
        document.querySelector('button[name="add-to-cart"]').click();
      }, 0);
    };

  const clickHandler = (index) => (e) => {
    e.preventDefault();
    const present = accordions.indexOf(index);
    if (present > -1) {
      const result = [...accordions];
      result.splice(present, 1);
      setAccordions([...result]);
    } else {
      setAccordions([...new Set([...accordions, index])]);
    }
  };

  if (
    // inline style hack
    !builderState.extras ||
    (builderState.extras && !builderState.extras.iconDir)
  ) {
    return null;
  }

  const togglePerson = (person) => (e) => {
    e.preventDefault();
    setBuilderState({
      ...builderState,
      [person]: {
        ...builderState[person],
        disabled: builderState[person].disabled ? false : true,
      },
    });
  };

  return (
    <div className="modal-body">
      <nav className="navbar navbar-light header justify-content-between">
        <div className="navbar-brand" id="kubkozaCreatorModalLabel">
          Konfigurator <strong>{projectName}</strong>
        </div>
        <button type="button" className="btn-close" onClick={closeHandler()}>
          <span className="wcboost-products-compare-popup__close-icon">
            <svg
              width="20"
              height="20"
              role="image"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
            </svg>{" "}
          </span>
        </button>
      </nav>
      <div className="main-wrapper">
        <div className="row my-row">
          <div className="col-md-5 preview-wrapper sticky-sm-top sticky-top">
            <Canva
              options={options}
              state={builderState}
              assetsName={assetsName}
              canvasRef={canvasRef}
            ></Canva>
          </div>
          <div className="col-md-7">
            <div className="accordion mt-2" id="accordionOptions">
              {Object.entries(options)
                .filter(([key]) => key !== "extras")
                .map(
                  (
                    [key, { name: personName, disableable, options }],
                    index
                  ) => (
                    <div className="accordion-item" key={key}>
                      <h2 className="accordion-header" id={`heading${key}`}>
                        <button
                          className="accordion-button"
                          type="button"
                          onClick={clickHandler(index)}
                        >
                          {personName}
                          {builderState[key].disabled ? " (niewidoczne)" : ""}
                        </button>
                      </h2>
                      <div
                        id={`collapse${key}`}
                        className={`accordion-collapse collapse ${
                          accordions.includes(index) ? "show" : ""
                        }`}
                        aria-labelledby={`#heading${key}`}
                      >
                        <div className="accordion-body">
                          {disableable ? (
                            <button onClick={togglePerson(key)}>
                              {" "}
                              {builderState[key].disabled
                                ? "Pokaż"
                                : "Ukryj"}: {personName}
                            </button>
                          ) : null}
                          <div
                            className={
                              builderState[key].disabled ? "hidden" : null
                            }
                          >
                            {Object.entries(options || {}).map(
                              ([
                                option,
                                {
                                  name: optionName,
                                  options,
                                  hidden,
                                  text,
                                  general,
                                },
                              ]) => {
                                if (hidden) return null;
                                return (
                                  <div key={option} className="mb-4">
                                    <h4>{optionName}</h4>
                                    {text && (
                                      <div className="row-cols-auto">
                                        <div className="col">
                                          <input
                                            type="text"
                                            placeholder={`np. ${text.value}`}
                                            className="form-control"
                                            value={
                                              builderState[key]
                                                ? builderState[key][option]
                                                : ""
                                            }
                                            onChange={(e) => {
                                              return selectOption([
                                                key,
                                                option,
                                                `${e.currentTarget.value}`,
                                              ])(e);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {options && (
                                      <div className="row-cols-auto">
                                        {options.map(
                                          ({
                                            name: valueName,
                                            value,
                                            empty = false,
                                          }) => (
                                            <div
                                              className={`col item-wrapper ${
                                                isSelected(
                                                  key,
                                                  option,
                                                  value
                                                ) && "active"
                                              }`}
                                              key={`${option}_${value}`}
                                              onClick={selectOption([
                                                key,
                                                option,
                                                value,
                                              ])}
                                            >
                                              <span
                                                className={`item contain ${
                                                  option === "bg" ? "large" : ""
                                                }`}
                                                style={{
                                                  backgroundImage: `url("${
                                                    process.env
                                                      .REACT_APP_PUBLIC_URL
                                                  }/icons${
                                                    option === "bg"
                                                      ? ""
                                                      : `/${builderState.extras.iconDir}`
                                                  }/${option.replace(
                                                    /_\d+/,
                                                    ""
                                                  )}/${
                                                    general || option === "bg"
                                                      ? ""
                                                      : `${key}_`
                                                  }${value}.jpg")`,
                                                  ...(empty
                                                    ? {
                                                        backgroundImage: `url("${process.env.REACT_APP_PUBLIC_URL}/icons/bg/0.jpg")`,
                                                      }
                                                    : null),
                                                }}
                                              >
                                                {valueName}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className="accordion mt-2">
              <div className="accordion-item">
                <div className="accordion-header">Ustawienia</div>
                <div className="accordion-collapse show">
                  <div className="accordion-body">
                    <div className="row my-row">
                      <div className="col-12 col-md-6 mb-4">
                        <h5 className="card-title">Krój czcionki</h5>
                        <select
                          className="form-select"
                          onChange={setExtras("fontFamily")}
                        >
                          {fonts.map((name, index) => (
                            <option
                              value={name}
                              key={name}
                              {...(builderState.extras &&
                              builderState.extras.fontFamily === name
                                ? { selected: true }
                                : null)}
                            >
                              Czionka {index + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h5 className="card-title">Wielkość tekstu</h5>
                        <div className="card-text">
                          <input
                            type="range"
                            className="form-range"
                            min="40"
                            max="70"
                            step="1"
                            onChange={setExtras("fontSize", (i) => i)}
                            value={
                              (builderState.extras &&
                                builderState.extras.fontSize) ||
                              60
                            }
                          />
                          <div className="col-12 justify-content-between">
                            <label className="col">Mniejszy</label>
                            <label className="col text-end">Większy</label>
                          </div>
                        </div>
                      </div>
                      {builderState.background &&
                      builderState.background.bg !== -2 ? (
                        <>
                          <div className="col-12 col-md-6 mb-4">
                            <h5 className="card-title">Widoczność tła</h5>
                            <div className="card-text">
                              <input
                                type="range"
                                className="form-range"
                                min="2"
                                max="8"
                                step="0.5"
                                onChange={setExtras("bgAlpha", (i) => i / 10)}
                                value={
                                  (builderState.extras &&
                                    builderState.extras.bgAlpha * 10) ||
                                  5
                                }
                              />
                              <div className="col-12 justify-content-between">
                                <label className="col">Mniejsza</label>
                                <label className="col text-end">Większa</label>
                              </div>
                            </div>
                          </div>
                          {!isIos() ? (
                            <div className="col-12 col-md-6 mb-4">
                              <h5 className="card-title">Rozmycie tła</h5>
                              <div className="card-text">
                                <input
                                  type="range"
                                  className="form-range"
                                  min="0"
                                  max="5"
                                  onChange={setExtras("bgBlur", (i) => i * 1)}
                                  value={
                                    builderState.extras
                                      ? builderState.extras.bgBlur
                                      : 0
                                  }
                                />
                                <div className="col-12 justify-content-between">
                                  <label className="col">Mniejsze</label>
                                  <label className="col text-end">
                                    Większe
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-12 col-md-6 mb-4">
                            <h5 className="card-title">Położenie tła</h5>
                            <div className="card-text">
                              <input
                                type="range"
                                className="form-range"
                                min="-5"
                                max="5"
                                onChange={setExtras("bgPosition", (i) => i * 1)}
                                value={
                                  builderState.extras
                                    ? builderState.extras.bgPosition
                                    : 0
                                }
                              />
                              <div className="col-12 justify-content-between">
                                <label className="col">Niżej</label>
                                <label className="col text-end">Wyżej</label>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-bottom navbar-light footer">
          <div className="container-fluid cta-buttons justify-content-between">
            <a className="me-2 hidden-xs" href="https://kubkoza.pl/">
              <img
                src="https://kubkoza.pl/wp-content/uploads/2023/03/logo.png"
                width="388"
                height="100"
                alt="Kubkoza"
                className="logo"
              />
            </a>
            <div className="add-co-cart-section">
              <button
                type="button"
                className="addtocart addtocart-alt"
                onClick={finishCreator()}
              >
                Chcę tylko kubek
              </button>
              <button
                type="button"
                className="addtocart"
                onClick={finishCreator("turbo")}
              >
                Kubek pełen słodkości
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Builder;
