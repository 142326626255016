import Builder from "components/Builder";
import React, { useEffect, useState } from "react";
// import "styles/bootstrap.min.css";
import "styles/globals.scss";

export default function App({ project, assets, disabledOptions, callback }) {
  const [options, setOptions] = useState({});
  const [error, setError] = useState(false);

  const loadAssets = async (assets) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await import(`assets/${assets}`);
        resolve(result.default);
      } catch (e) {
        reject(e);
      }
    });
  };

  useEffect(() => {
    loadAssets(assets)
      .then(setOptions)
      .catch((e) => {
        setError(true);
      });
  }, [assets, options]);

  const closeHandler = () => (e) => {
    e.preventDefault();

    document.getElementById("kubkozaCreatorModal").classList.remove("show");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("modal-body-overflow");
  };

  return (
    <div className="modal" id="kubkozaCreatorModal" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          {!assets && !error ? (
            <div className="main-wrapper">Wystąpił nieoczekiwany błąd</div>
          ) : (
            <Builder
              options={options}
              assetsName={assets}
              projectName={project}
              disabledOptions={disabledOptions}
              callbackFn={callback}
              closeHandler={closeHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
}
