import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const kubkozaCreatorWidget = ({
  targetEl,
  project,
  assets,
  disabledOptions,
  callback,
}) => {
  const root = ReactDOM.createRoot(targetEl);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App
          project={project}
          assets={assets}
          callback={callback}
          disabledOptions={disabledOptions}
        />
      </BrowserRouter>
    </React.StrictMode>
  );
};

if (document.getElementById("testEnv123")) {
  const searchParams = new URLSearchParams(document.location.search);

  kubkozaCreatorWidget({
    targetEl: document.getElementById("kubkozaWidgetRoot"),
    project: searchParams.get("project"),
    assets: searchParams.get("assets"),
    disabledOptions: (searchParams.get("disabledOptions") || "").split(","),
    callback: ({ state, image }) => {
      console.log("result", { state, image });
    },
  });
} else {
  if (document.getElementById("mug_design")) {
    const targetEl = document.getElementById("kubkozaWidgetRoot");

    kubkozaCreatorWidget({
      targetEl,
      project: targetEl.getAttribute("data-project"),
      assets: targetEl.getAttribute("data-assets"),
      disabledOptions: (
        targetEl.getAttribute("data-disabled-options") || ""
      ).split(","),
      callback: ({ state, image }) => {
        const mug_design = document.getElementById("mug_design");
        mug_design.value = JSON.stringify(state);

        const mug_image = document.getElementById("mug_image");
        mug_image.value = image;
        console.log("result", { state, image });
      },
    });
  }
}
