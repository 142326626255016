import { useEffect, useState } from "react";

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
const getActiveOptions = (options) =>
  Object.entries(options)
    .filter(
      ([name, value]) =>
        !value.disabled && !["extras", "background"].includes(name)
    )
    .map(([name]) => name);

function Canva({ options, state, assetsName, canvasRef }) {
  const [results, setResults] = useState([]);
  const [reloading, setReloading] = useState(true);
  const [loadedFonts, setLoadedFonts] = useState([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    canvas.width = options.extras.width || 960;
    canvas.height = options.extras.height || 1157;

    context.clearRect(0, 0, canvas.width, canvas.height);

    const loadFont = (name) => {
      return new Promise((resolve, reject) => {
        if (loadedFonts.includes(name)) {
          return resolve();
        }
        const font = new FontFace(
          name,
          `url(${process.env.REACT_APP_PUBLIC_URL}/fonts/${name}-Regular.ttf)`
        );
        font
          .load()
          .then((res) => {
            setLoadedFonts(name);
            document.fonts.add(res);
            setTimeout(() => resolve(), 250);
          })
          .catch(reject);
      });
    };

    const drawText = (text = "", position = () => [0, 0]) => {
      loadFont(state.extras.fontFamily).then(() => {
        context.font = `${state.extras.fontSize}px ${state.extras.fontFamily}`;
        const m = context.measureText(text);
        context.save();
        const [x, y, rotate] = position(m, canvas);
        if (rotate) {
          context.translate(x, y);
          context.rotate(rotate);
          context.fillText(text, 0, 0);
        } else {
          context.fillText(text, x, y);
        }
        context.restore();
      });
    };

    const loadedImages = [...results]
      .filter(({ module }) => !!module)
      .sort(({ params: { zindex: a } }, { params: { zindex: b } }) => a - b)
      .map(
        ({ module, params }) =>
          new Promise((resolve) => {
            const image = new Image();
            image.setAttribute("crossorigin", "anonymous");
            image.src = module;
            image.onload = function () {
              resolve({ image, params });
            };
          })
      );

    Promise.all(loadedImages).then((res) => {
      res.forEach(({ image, params }, index) => {
        if (params.disabled) {
          return;
        }
        const imageWidth = image.width * 0.85;
        const imageHeight = image.height * 0.85;
        context.globalAlpha = params.alpha;
        if (params.blur) {
          context.filter = `blur(${params.blur}px)`;
        }
        const [xOffset, yOffset] = params.offset || [0, 0];
        context.drawImage(
          image,
          (canvas.width - imageWidth) / 2 + xOffset,
          (canvas.height - imageHeight) / 2 + yOffset,
          imageWidth,
          imageHeight
        );
        context.globalAlpha = 1;
        if (params.blur) {
          context.filter = "blur(0px)";
        }
        delete results[index].image;
      });

      [...results]
        .filter(({ text }) => !!text)
        .forEach(({ text, params: { position, disabled } }) => {
          if (disabled) {
            return;
          }
          drawText(text, position);
        });
    });
  }, [results]);

  useEffect(() => {
    const importAsset = (key, option, value) => {
      const person = capitalize(key);
      const part = capitalize(option).replace(/_\d+/, "");

      return new Promise(async (resolve) => {
        try {
          const result =
            option === "bg"
              ? await import(`assets/bg/${value}.png`)
              : await import(
                  `assets/${assetsName}/${person}/${part}/${
                    value === 1 ? part : `${part} ${value}`
                  }.png`
                );
          return resolve({
            module: result.default,
            params: {
              disabled: state[key].disabled,
              alpha:
                (option === "bg" &&
                  (state.extras.bgAlpha || options.extras.bgAlpha)) ||
                1,
              blur:
                (option === "bg" &&
                  (state.extras.bgBlur || options.extras.bgBlur)) ||
                0,
              offset: (options[key].offset &&
                options[key].offset(getActiveOptions(state))) || [
                  0,
                  (option === "bg" &&
                    state.extras.bgPosition * -state.extras.bgPositionDelta) ||
                    0,
                ] || [0, 0],
              zindex:
                options[key].zindex +
                (options[key].options[option].zindex || 0),
            },
          });
        } catch (e) {
          return resolve();
        }
      });
    };

    setReloading(true);
    const _promises = [];
    setTimeout(() => {
      Object.entries({ ...state }).forEach(([key, _options]) => {
        Object.entries(_options)
          .filter(([option]) => !Object.keys(state.extras).includes(option))
          .forEach(([option, value]) => {
            if (isNaN(value)) {
              _promises.push({
                text: value,
                params: {
                  disabled: state[key].disabled,
                  position: (...args) => {
                    const [positionX, positionY, rotate] = options[key].options[
                      option
                    ].text.position(...args);
                    const [offsetX, offsetY] = (options[key].offset &&
                      options[key].offset(getActiveOptions(state))) || [0, 0];
                    return [offsetX + positionX, offsetY + positionY, rotate];
                  },
                  zindex: 10000,
                },
              });
            } else {
              _promises.push(importAsset(key, option, value));
            }
          });
      });

      if (_promises.length) {
        Promise.all(_promises).then((result) => {
          setResults([...result.filter(Boolean)]);
          setTimeout(() => setReloading(false), 500);
        });
      } else {
        setTimeout(() => setReloading(false), 500);
      }
    }, 150);
  }, [state, options]);

  return (
    <div className="preview">
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      {/* <div className={`loader ${reloading ? "show" : null}`}></div> */}
      <canvas ref={canvasRef}></canvas>
    </div>
  );
}

export default Canva;
